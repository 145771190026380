import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
//引入store
import store from './store'
import { isAuth } from '@/utils'

 import Cookies from 'js-cookie'
// 引入ElementUI组件库
import ElementUI from 'element-ui'
//引入ElementUI的所有样式
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/common.css'//自定义全局样式
//引入vue-amap
import VueAMap from 'vue-amap'
//引入echarts文件
// import * as echarts from 'echarts'
 import * as echarts from 'echarts5';
 //引入公共的接口文件
 import api from '@/api/index.js'
import {handleTree} from '@/utils/util.js'


// 全局注册 webSocket
import webSocket from '@/WebSocket.js'
import screenfull from "screenfull"
Vue.prototype.$webSocket = webSocket
Vue.prototype.$echarts = echarts
Vue.prototype.isAuth = isAuth     // 权限方法
Vue.prototype.$api = api;//请求接口
Vue.prototype.handleTree = handleTree;//树型结构
Vue.prototype.screenfull = screenfull
// 新旧版本
Vue.prototype.$version = 'old';//旧版本

import {
	MessageBox,
	Message
} from 'element-ui' //引入element Ui的消息提示框组件 


import httpRequest from '@/utils/httpRequest'

Vue.use(ElementUI)
Vue.use(VueAMap)

VueAMap.initAMapApiLoader({
	key: 'da17a1a27fc266d0b3f025c2f405269d',
    //v:'2.0', //TODO 更换为新版本API 默认是1.4.4
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.ElasticMarker','AMap.GraspRoad','AMap.Geocoder'],
	uiVersion:'1.1'
})


//关闭Vue的生产提示
Vue.config.productionTip = false
Vue.prototype.$http = httpRequest

//引入echarts
// import echarts from 'echarts';
// Vue.prototype.$echarts = echarts
// Vue.use(echarts)


router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

new Vue({
	render: h => h(App),
	router,
	store,
}).$mount('#app')
