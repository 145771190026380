// 测试环境
// var domain = "http://59.110.141.57:8080/";
var domain = "https://wtss2.junantaikeji.com/prod-api/";
// var domain = "http://192.168.124.42:8080/";//何
// var domain = "http://192.168.124.18:8080/";//栋
// var domain = "http://192.168.124.6:8080/";//浩
// var picBaseUrl = "http://59.110.141.57:8080/";//图片上传
var picBaseUrl = "https://wtss2.junantaikeji.com/prod-api/";//图片上传
// var picBaseUrl = "http://192.168.124.42:8080/";

var picDomain = "https://res.junantaikeji.com/";
// var webSocketDomain = "ws://59.110.141.57:8090/im/websocket?token="+(localStorage.getItem("Authorization") ? localStorage.getItem("Authorization").slice(7) : '');
var webSocketDomain = "wss://wtss2.junantaikeji.com/im/im/websocket?token="+(localStorage.getItem("Authorization") ? localStorage.getItem("Authorization").slice(7) : '');
// var webSocketDomain = "ws://192.168.124.6:8090/im/websocket?token="+(localStorage.getItem("Authorization") ? localStorage.getItem("Authorization").slice(7) : '');
var gaoDeMapKey='da17a1a27fc266d0b3f025c2f405269d';

exports.domain = domain;
exports.picDomain = picDomain;
exports.picBaseUrl = picBaseUrl;
exports.webSocketDomain = webSocketDomain;
exports.gaoDeMapKey = gaoDeMapKey;