/**
 * 岗位管理用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const position = {
	//岗位列表(筛选)
	postList(data){
		return http({
			url: http.adornUrl('post/page'),
			method: "get",
			params:data
		})
	},
	//岗位列表(供选择岗位使用，不能筛选)
	postData(){
		return http({
			url: http.adornUrl('post/list'),
			method: "get"
		})
	},
	//新增岗位
	addPost(data){
		return http({
			url: http.adornUrl('post'),
			method: "post",
			data
		})
	},
	//编辑岗位
	editPost(data){
		return http({
			url: http.adornUrl('post'),
			method: "put",
			data
		})
	},
	//删除岗位
	deletePost(id){
		return http({
			url: http.adornUrl('post/')+id,
			method: "delete"
		})
	}
}
export default position;