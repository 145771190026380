/**
 * 大屏用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const largeScreen = {
	//车辆分组
	carGroup(){
		return http({
			url: http.adornUrl('workforceManagement/getGroupList'),
			method: "get"
		})
	},
	//任务概览，当前用车，总计车辆等
	carInfo(data){
		return http({
			url: http.adornUrl('displayScreen/getDisplayScreenInfo'),
			method: "get",
			params:data
		})
	},
	//用车概况
	vehicleOverview(data){
		return http({
			url: http.adornUrl('displayScreen/getVehicleOverview'),
			method: "get",
			params:data
		})
	},
	//车务费用排名
	busFareRanking(data){
		return http({
			url: http.adornUrl('displayScreen/getBusFareRanking'),
			method: "get",
			params:data
		})
	},
	//司机任务排名
	driverTaskRanking(data){
		return http({
			url: http.adornUrl('displayScreen/getDriverTaskRanking'),
			method: "get",
			params:data
		})
	},
	//用车记录
	recordTrend(data){
		return http({
			url: http.adornUrl('displayScreen/getCarUsageRecordTrend'),
			method: "get",
			params:data
		})
	},
	//根据车牌号查询用车人
	userByVehiclesUsed(data){
		return http({
			url: http.adornUrl('user/getUserByVehiclesUsed'),
			method: "get",
			params:data
		})
	}
}
export default largeScreen;