/**
 * 车辆排班用到的接口
 */
import http from '@/utils/httpRequest.js'
const vehicleScheduling = {
	//菜单列表
	menuList(data){
		return http({
			url: http.adornUrl('menu'),
			method: "get"
		})
	}
}
export default vehicleScheduling;