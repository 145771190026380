import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from "js-cookie";
const routerPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {          
	return routerPush.call(this, location).catch(error=> error)
}
Vue.use(VueRouter)

const router = new VueRouter({
	routes: [{
		path: '/',
		name: 'main',
		meta: {
			title: '主入口整体布局'
		},
		redirect: '/index'
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登录页'
		},
		component: () => import('@/views/login.vue')
	},	
	{
		path: '/forgot-password',
		name: 'forgotPassword',
		meta: {
			title: '忘记密码'
		},
		component: () => import('@/views/forgotPassword.vue')
	},
	{
		path: '/index',
		name: 'index',
		meta: {
			title: '实时监控'
		},
		redirect: {
			// name: 'liveSurveillance',
			// path: 'liveSurveillance',
			// path: '/online-map',
			// query: {
			// 	sitesId: 1000,
			// 	msitesId: 1100
			// },
			path: '/stage-home',
			query: {
				sitesId: 8739,
				msitesId: 8740
			}
		},
		component: () => import('@/views/index.vue'),
		children: [{
			path: '/content-center',
			name: 'contentCenter',
			meta: {
				title: ''
			},
			component: () => import('@/views/content-center.vue'),
			children: [{
				path: '/online-map',
				name: 'onlineMap',
				meta: {
					title: '实时监控'
				},
				component: () => import('@/views/online-map.vue')
			},
			{
				path: '/police-attendance',
				name: 'policeAttendance',
				meta: {
					title: '出警管理'
				},
				component: () => import('@/views/police-attendance.vue')
			},
			{
				path: '/police-manege',
				name: 'policeManege',
				meta: {
					title: '警员管理'
				},
				component: () => import('@/views/police-manage.vue')
			},
			{
				path: '/warn-record',
				name: 'warnRecord',
				meta: {
					title: '告警记录'
				},
				component: () => import('@/views/warn-record.vue')
			},
			{
				path: '/guijitest',
				name: 'guijitest',
				meta: {
					title: '轨迹地图(测试)'
				},
				component: () => import('@/views/guijitest.vue')
			},
			{
				path: '/car-list',
				name: 'carList',
				meta: {
					title: '车辆列表'
				},
				component: () => import('@/views/car-list.vue')
			},
			// {
			// 	path: '/car-list-new',
			// 	name: 'carListNew',
			// 	meta: {
			// 		title: '车辆管理'//公务用车-车辆管理
			// 	},
			// 	component: () => import('@/views/car-list-new.vue')
			// },
			{
				path: '/police-car-management',
				name: 'policeCarManagement',
				meta: {
					title: '车辆管理'//警务用车-车辆管理
				},
				component: () => import('@/views/police-car-management.vue')
			},
			{
				path: '/device-list',
				name: 'deviceList',
				meta: {
					title: '设备管理'
				},
				component: () => import('@/views/device-list.vue')
			},
			// {
			// 	path: '/driver-list',
			// 	name: 'driverList',
			// 	meta: {
			// 		title: '司机管理'
			// 	},
			// 	component: () => import('@/views/driver-list.vue')
			// },
			{
				path: '/vehicle-records',
				name: 'vehicleRecords',
				meta: {
					title: '用车记录'
				},
				component: () => import('@/views/vehicle-records.vue')
			},
			{
				path: '/department-vehicle-records',
				name: 'departmentVehicleRecords',
				meta: {
					title: '部门用车记录'
				},
				component: () => import('@/views/department-vehicle-records.vue')
			},
			{
				path: '/police-vehicle-records',
				name: 'policeVehicleRecords',
				meta: {
					title: '用车记录'//警务用车-用车记录
				},
				component: () => import('@/views/police-vehicle-records.vue')
			},
			{
				path: '/car-usage-report',
				name: 'carUsageReport',
				meta: {
					title: '用车统计'
				},
				component: () => import('@/views/car-usage-report.vue')
			},
			{
				path: '/department-usage-report',
				name: 'departmentUsageReport',
				meta: {
					title: '部门用车统计'
				},
				component: () => import('@/views/department-usage-report.vue')
			},
			{
				path: '/police-usage-report',
				name: 'policeUsageReport',
				meta: {
					title: '用车报表'//警务用车-用车报表
				},
				component: () => import('@/views/police-usage-report.vue')
			},
			{
				path: '/vehicle-maintenance-report',
				name: 'vehicleMaintenanceReport',
				meta: {
					title: '车务申报'
				},
				component: () => import('@/views/vehicle-maintenance-report.vue')
			},
			{
				path: '/police-maintenance-report',
				name: 'policeMaintenanceReport',
				meta: {
					title: '车务申报'//警务用车-车务申报
				},
				component: () => import('@/views/police-maintenance-report.vue')
			},
			{
				path: '/apply-examine-list',
				name: 'applyExamineList',
				meta: {
					title: '申请审批'
				},
				component: () => import('@/views/apply-examine-list')
			},
			{
				path: '/carDataList',
				name: 'carDataList',
				meta: {
					title: '车辆数据'
				},
				component: () => import('@/views/carDataList.vue')
			},
			{
				path: '/track-query',
				name: 'trackQuery',
				meta: {
					title: '轨迹查车'
				},
				component: () => import('@/views/track-query.vue')
			},
			// {
			// 	path: '/fence-management',
			// 	name: 'fence-management',
			// 	meta: {
			// 		title: '围栏管理'
			// 	},
			// 	component: () => import('@/views/fence-management.vue')
			// },
			{
				path: '/violation-record',
				name: 'violationRecord',
				meta: {
					title: '违章记录'
				},
				component: () => import('@/views/violation-record.vue')
			},
			{
				path: '/alarm-record',
				name: 'alarmRecord',
				meta: {
					title: '报警记录'
				},
				component: () => import('@/views/alarm-record.vue')
			},
			{
				path: '/statistics',
				name: 'statistics',
				meta: {
					title: '数据统计'
				},
				component: () => import('@/views/statistics.vue')
			},
			{
				path: '/traffic-data',
				name: 'trafficData',
				meta: {
					title: '车流量数据'
				},
				component: () => import('@/views/traffic-data.vue')
			},
			{
				path: '/car-category-data',
				name: 'carCategoryData',
				meta: {
					title: '车类别数据'
				},
				component: () => import('@/views/car-category-data.vue')
			},
			{
				path: '/peak-data',
				name: 'peakData',
				meta: {
					title: '高峰期数据'
				},
				component: () => import('@/views/peak-data.vue')
			},
			{
				path: '/roleList',
				name: 'roleList',
				meta: {
					title: '角色管理'
				},
				component: () => import('@/views/roleList.vue')
			},
			{
				path: '/menu-manage',
				name: 'menu-manage',
				meta: {
					title: '菜单管理'
				},
				component: () => import('@/views/menu-manage.vue')
			},
			
			{
				path: '/userList',
				name: 'userList',
				meta: {
					title: '用户列表'
				},
				component: () => import('@/views/userList.vue')
			},
			{
				path: '/permissionList',
				name: 'permissionList',
				meta: {
					title: '权限列表'
				},
				component: () => import('@/views/permissionList.vue')
			},
			{
				path: '/log',
				name: 'log',
				meta: {
					title: '操作日志'
				},
				component: () => import('@/views/log.vue')
			},
			{
				path: '/system-dispose',
				name: 'systemDispose',
				meta: {
					title: '系统配置'
				},
				component: () => import('@/views/system-dispose.vue')
			},
			{
				path: '/violation-manage',
				name: 'violationManage',
				meta: {
					title: '违章管理'
				},
				component: () => import('@/views/violation-manage.vue')
			},			
			{
				path: '/tenant-manage',
				name: 'tenantManage',
				meta: {
					title: '租户管理'
				},
				component: () => import('@/views/authorityManage/tenant-manage.vue')
			},
			{
				path: '/car-manage',
				name: 'carManage',
				meta: {
					title: '车辆管理'
				},
				component: () => import('@/views/basicSettings/car-manage.vue')
			},
			{
				path: '/driver-manage',
				name: 'driverManage',
				meta: {
					title: '司机管理'
				},
				component: () => import('@/views/basicSettings/driver-manage.vue')
			},
			{
				path: '/user-manage',
				name: 'userManage',
				meta: {
					title: '用户管理'
				},
				component: () => import('@/views/authorityManage/user-manage.vue')
			},
			{
				path: '/company-common-address',
				name: 'companyCommonAddress',
				meta: {
					title: '公司常用地址'
				},
				component: () => import('@/views/basicSettings/company-common-address.vue')
			},
			{
				path: '/company-common-address-label',
				name: 'companyCommonAddressLabel',
				meta: {
					title: '公司地址标签'
				},
				component: () => import('@/views/basicSettings/company-common-address-label.vue')
			},
			{
				path: '/department-manage',
				name: 'departmentManage',
				meta: {
					title: '部门管理'
				},
				component: () => import('@/views/authorityManage/department-manage.vue')
			},
			{
				path: '/group-management',
				name: 'groupManagement',
				meta: {
					title: '分组管理'
				},
				component: () => import('@/views/basicSettings/group-management.vue')
			},
			{
				path: '/post-manage',
				name: 'postManage',
				meta: {
					title: '岗位管理'
				},
				component: () => import('@/views/authorityManage/post-manage.vue')
			},
			{
				path: '/approval-settings',
				name: 'approvalSettings',
				meta: {
					title: '审批设置'
				},
				component: () => import('@/views/basicSettings/approval-settings.vue')
			},
			{
				path: '/senior-settings',
				name: 'seniorSettings',
				meta: {
					title: '高级设置'
				},
				component: () => import('@/views/basicSettings/senior-settings.vue')
			},
			{
				path: '/vehicle-schedule',
				name: 'vehicleSchedule',
				meta: {
					title: '车辆排班'
				},
				component: () => import('@/views/scheduleManage/vehicle-schedule.vue')
			},
			{
				path: '/driver-schedule',
				name: 'driverSchedule',
				meta: {
					title: '司机排班'
				},
				component: () => import('@/views/scheduleManage/driver-schedule.vue')
			},
			{
				path:'/large-screen',
				name:'largeScreen',
				meta:{
					title:'管控中心'
				},
				component:()=>import('@/views/screen/large-screen.vue')
			},
			{
				path:'/large-screenold',
				name:'largeScreen',
				meta:{
					title:'管控中心'
				},
				component:()=>import('@/views/screen/large-screenold.vue')
			},
			{
				path: '/stage-home',
				name: 'stageHome',
				meta: {
					title: '工作台'
				},
				component: () => import('@/views/staging/stage-home.vue')
			},
			{
				path: '/task-home',
				name: 'taskHome',
				meta: {
					title: '我的任务'
				},
				component: () => import('@/views/my-tasks/task-home.vue')
			},
			{
				path:'/fuel-con-monitor',
				name:'fuelConMonitor',
				meta:{
					title:'油耗监控'
				},
				component: () => import('@/views/fuel-con-monitor.vue')
			},
			{
				path:'/ful-detail',
				name:'fulDetail',
				meta:{
					title:'油耗监控详情'
				},
				component: () => import('@/views/fuelDetail.vue')
			},
			{
				path:'/user-feedback',
				name:'userFeedback',
				meta:{
					title:'用户反馈'
				},
				component: () => import('@/views/basicSettings/user-feedback.vue')
			},
			{
				path: '/vehicle-analysis',
				name: 'vehicleAnalysis',
				meta: {
					title: '车辆分析'
				},
				component: () => import('@/views/basicSettings/vehicle-analysis.vue')
			}
			]
		},
		{
			path: '/liveSurveillance',
			name: 'liveSurveillance',
			meta: {
				title: '实时监控'
			},
			component: () => import('@/views/liveSurveillance.vue')
		},
		{
			path: '/vehicleSupervision',
			name: 'vehicleSupervision',
			meta: {
				title: '车辆监管'
			},
			component: () => import('@/views/vehicleSupervision.vue'),
		},
		{
			path: '/officialVehicles',
			name: 'officialVehicles',
			meta: {
				title: '公务用车'
			},
			component: () => import('@/views/officialVehicles.vue'),
		},
		{
			path: '/policeVehicles',
			name: 'policeVehicles',
			meta: {
				title: '警务用车'
			},
			component: () => import('@/views/policeVehicles.vue'),
		},
		{
			path: '/specialVehicles',
			name: 'specialVehicles',
			meta: {
				title: '特殊车辆'
			},
			component: () => import('@/views/specialVehicles.vue'),
		},
		
		{
			path: '/carManagement',
			name: 'carManagement',
			meta: {
				title: '车辆管理'
			},
			component: () => import('@/views/carManagement.vue'),
		},
		{
			path: '/dataSupport',
			name: 'dataSupport',
			meta: {
				title: '数据支持'
			},
			component: () => import('@/views/dataSupport.vue')
		},
		{
			path: '/systemManagement',
			name: 'systemManagement',
			meta: {
				title: '系统管理'
			},
			component: () => import('@/views/systemManagement.vue')
		},
		{
			path: '/user-center',
			name: 'userCenter',
			meta: {
				title: '个人中心'
			},
			component: () => import('@/views/user-center.vue')
		},
		{
			path: '/modify-password',
			name: 'modifyPassword',
			meta: {
				title: '修改密码'
			},
			component: () => import('@/views/modify-password.vue')
		},
		{
			path: '/modify-mobile',
			name: 'modifyMobile',
			meta: {
				title: '修改手机号码'
			},
			component: () => import('@/views/modify-mobile.vue')
		},
		{
			path: '/authorityManage',
			name: 'authorityManage',
			meta: {
				title: '权限管理'
			},
			component: () => import('@/views/authorityManage/authorityManage.vue')
		},
		{
			path: '/basicSettings',
			name: 'basicSettings',
			meta: {
				title: '基础设置'
			},
			component: () => import('@/views/basicSettings/basicSettings.vue')
		},
		{
			path: '/scheduleManage',
			name: 'scheduleManage',
			meta: {
				title: '排班管理'
			},
			component: () => import('@/views/scheduleManage/scheduleManage.vue')
		},
		{
			path: '/staging',
			name: 'staging',
			meta: {
				title: '工作台'
			},
			component: () => import('@/views/staging/staging.vue')
		},
		{
			path: '/my-tasks',
			name: 'myTasks',
			meta: {
				title: '我的任务'
			},
			component: () => import('@/views/my-tasks/my-tasks.vue')
		}
		]
	},
	{
		path: '/h5/track-query',
		name: 'h5trackQuery',
		meta: {
			title: '轨迹查车'
		},
		component: () => import('@/views/h5/track-query.vue')
	},	
	{
		path: '/h5/online-map',
		name: 'onlineMap',
		meta: {
			title: '实时监控'
		},
		component: () => import('@/views/h5/online-map.vue')
	},
	{
		path: '/h5/track-playback',
		name: 'trackPlayback',
		meta: {
			title: '轨迹回放'
		},
		component: () => import('@/views/h5/track-playback.vue')
	},
	{
		path: "/404",
		name: "NotFound",
		component: () => import("@/views/common/404.vue"),
		hidden: true,
		meta: { title: "404" },
	},
	// 当什么都没有匹配到的时候，重定向页面到 404 页面
	{ 
		path: "/:pathMatch(.*)", 
		redirect: "/404", 
		name: "notMatch", 
		hidden: true 
	},
	]
})
router.beforeEach((to, from, next) => {
	if(from.path === '/'){
	  Cookies.set("refresh",true);
	}else{
	  Cookies.remove("refresh");
	}
	if(to.query.token){
	  localStorage.setItem("Authorization",to.query.token);
	}
	//实时监控，轨迹查车，轨迹回放
	if(to.fullPath.includes("/h5/track-query") || to.fullPath.includes("/h5/online-map") || to.fullPath.includes("/h5/track-playback")){
	  //修改页面标题
	  document.title = to.meta.title
	}
	let authorization = localStorage.getItem("Authorization")
	if (to.name !== 'login' && to.name !== 'forgotPassword' && !authorization) next({ name: 'login' })
	else next()
})
export default router
